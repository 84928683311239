(function (m, e, t, r, i, k, a) {
        m[i] = m[i] || function () { (m[i].a = m[i].a || []).push(arguments) };
        m[i].l = 1 * new Date(); k = e.createElement(t), a = e.getElementsByTagName(t)[0], k.async = 1, k.src = r, a.parentNode.insertBefore(k, a)
})
        (window, document, "script", "https://mc.yandex.ru/metrika/tag.js", "ym");

ym(48045371, "init", {
        clickmap: true,
        trackLinks: true,
        accurateTrackBounce: true,
        webvisor: true
});

window.replainSettings = { id: 'baa34fb0-306a-469e-912c-061f9c1f8df2' };
(function (u) {
        var s = document.createElement('script'); s.async = true; s.src = u;
        var x = document.getElementsByTagName('script')[0]; x.parentNode.insertBefore(s, x);
})('https://widget.replain.cc/dist/client.js');

